import i18n from '@/i18n';
import axios from 'axios';
import orderBy from 'lodash/orderBy';
import { valueSortByNumber } from './sort';
import { DateTime } from 'luxon';
import { getDynamicFieldValue } from './helpers';

// Sort the values per price (ASC)
function parseAndSort(options: OptionGroup[], restaurant: Restaurant) {

	// If the option group has an order, we have to sort them, otherwise keep going
	if(options.some(optionGroup => optionGroup.order )) {
		options.sort((a: OptionGroup, b: OptionGroup) => valueSortByNumber(a, b, 'order'));
	}
	options.map((optionGroup: OptionGroup) => {
		if(optionGroup.values && optionGroup.values.length) {

			// Verify if the default price matches its scheduled price
			for (let index = 0; index < optionGroup.values.length; index++) {
				let option = optionGroup.values[index];

				// Set checked flag for later (visual addons selection)
				option.checked = false;
				option = getPriceAndAvailability(option) as Option;

				option.calories = Number.isInteger(option.calories) && option.calories! >= 0 ? option.calories : null;
				option.quantity = 1;
			}

			// If the options has an order, we have to sort them by it, otherwise sort
			// by price.
			if(optionGroup.values.some(option => option.order )) {
				optionGroup.values.sort((a: Option, b: Option) => valueSortByNumber(a, b, 'order'));
			}
			else {
				optionGroup.values.sort((a: Option, b: Option) => valueSortByNumber(a, b, 'price'));
			}

			// Repeat if more options (Treats it as an object, transform into an array for recursivity)
			// Also manipulation this right here in case we wanna change the structure to having more than
			// one option group per options, it'll be an easier change if we treat it already as an array.
			optionGroup.values.map((option: any) => {
				// If for some reason roll up isn't present, we set it to true as it is its default
				if(typeof option.roll_up === 'undefined') {
					option.roll_up = true;
				}

				if(option.options && option.options.name) {
					option.options = [option.options];
					parseAndSort(option.options, restaurant);
				}
			});
		}
	});
}

function sortOption(options: OptionGroup[], restaurant: Restaurant): OptionGroup[] {
	if(options && options.length) {

		// Sort the values per price (ASC)
		parseAndSort(options, restaurant);

		// Put the pricing option into its own array to be treated differently
		const pricingOption = options.find(option => option.type === 'pricing');
		if(pricingOption) {
			const addonsOption = options.filter(option => option.type !== 'pricing');
			const newOptionArray = [pricingOption, ... addonsOption];
			return newOptionArray;
		}
	}
	return options;
}

function getPriceAndAvailability(item: MenuItem | Option): MenuItem | Option {
	const dateObj: DateTime = DateTime.local();
	// Get the item's schedule of the day
	const dayOfWeek: number = dateObj.weekday;

	if (item.price_schedule && item.price_schedule.length) {
		const itemDaySchedule = item.price_schedule[dayOfWeek - 1];

		// Instantiate the date that will be used for scheduling to beginning of the day
		const today = dateObj.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
		const todayDiff = dateObj.diff(today, ['minutes']);
		const scheduleIndex = Math.floor(todayDiff.minutes / 15);

		// We validate the item price
		if(itemDaySchedule) {
			if(item.additional_prices && Object.keys(item.additional_prices)) {
				const itemPriceKey: string = itemDaySchedule[scheduleIndex];
				const itemScheduledPrice = item.additional_prices[itemPriceKey];

				// If the item price key is X, this means the item is unavailable to order
				// If the price does not exists in the extra prices, we throw.
				// We check for undefined since null or 0 could be accepted
				if (itemPriceKey && itemPriceKey.toLowerCase() === 'x' || typeof itemScheduledPrice === 'undefined') {
					item.unavailable = true;
					return item;
				}

				// If the default price does not match, we set the price to its scheduled price
				if (Number(item.price) !== Number(itemScheduledPrice)) {
					item.price = Number(itemScheduledPrice).toFixed(2);
				}
			}
		}
	}

	return item;
}

/**
 * Get tip config and get the free form fields depending on the locale
 *
 * @param {RestaurantOrderOptions | undefined} orderOptions
 * @param {RestaurantLocale[]} locales
 * @return {TipConfig | undefined}
 */
function getTipConfig(orderOptions: RestaurantOrderOptions | undefined, locales: RestaurantLocale[]): TipConfig | undefined {
	if(orderOptions && orderOptions.tip_config) {
		orderOptions.tip_config = {
			...orderOptions.tip_config,
			total_summary_title: getDynamicFieldValue(orderOptions.tip_config, orderOptions.localization, 'total_summary_title', locales)
		}
		return orderOptions.tip_config;
	}
	return;
}


/**
 * Get the extra payment methods and get the free form fields depending on the locale
 *
 * @param {RestaurantExtraPaymentMethods[] | undefined} extraPaymentMethods
 * @param {RestaurantLocale[]} locales
 * @return {RestaurantExtraPaymentMethods[] | undefined}
 */
function getExtraPaymentMethods(extraPaymentMethods: RestaurantExtraPaymentMethods[] | undefined, locales: RestaurantLocale[]): RestaurantExtraPaymentMethods[] | undefined {
	if(extraPaymentMethods?.length) {
		extraPaymentMethods = extraPaymentMethods.map((paymentMethod: RestaurantExtraPaymentMethods) => {
			paymentMethod = {
				...paymentMethod,
				label: getDynamicFieldValue(paymentMethod, paymentMethod.localization, 'label', locales),
			}
			return paymentMethod;
		});
		return extraPaymentMethods;
	}
	return;
}


/**
 * Get the voucher and get the free form fields depending on the locale
 *
 * @param {Voucher | undefined} voucher
 * @param {RestaurantLocale[]} locales
 * @return {Voucher | undefined}
 */
function getVoucher(voucher: Voucher | undefined, locales: RestaurantLocale[]): Voucher | undefined {
	if(voucher) {
		voucher = {
			...voucher,
			title: getDynamicFieldValue(voucher, voucher.localization, 'title', locales),
			tooltip: getDynamicFieldValue(voucher, voucher.localization, 'tooltip', locales)
		}
		return voucher;
	}
	return;
}

/**
 * Get the membership program customization and get the free form fields depending on the locale
 *
 * @param {RestaurantMenuConfiguration | undefined} menu_configuration
 * @param {RestaurantLocale[]} locales
 * @return {MembershipCustomization | undefined}
 */
function getMembershipProgram(menu_configuration: RestaurantMenuConfiguration | undefined, locales: RestaurantLocale[]): MembershipCustomization | undefined {
	if(menu_configuration && menu_configuration.membership_customization) {
		menu_configuration.membership_customization = {
			...menu_configuration.membership_customization,
			title: getDynamicFieldValue(menu_configuration.membership_customization, menu_configuration.localization, 'title', locales),
			tooltip: getDynamicFieldValue(menu_configuration.membership_customization, menu_configuration.localization, 'tooltip', locales),
			input_label: getDynamicFieldValue(menu_configuration.membership_customization, menu_configuration.localization, 'input_label', locales),
			button_text: getDynamicFieldValue(menu_configuration.membership_customization, menu_configuration.localization, 'button_text', locales),
			loading_message: getDynamicFieldValue(menu_configuration.membership_customization, menu_configuration.localization, 'loading_message', locales),
			banner_message: getDynamicFieldValue(menu_configuration.membership_customization, menu_configuration.localization, 'banner_message', locales)
		}
		return menu_configuration.membership_customization;
	}
	return;
}

/**
 * Get the menu or section info for an item.
 *
 * @param {MenuItem} item
 * @param {Menu} menu
 * @return {ItemParent}
 */
function getSectionOrMenuName(item: MenuItem, menu: Menu): ItemParent {
	const section = menu.sections?.find((section) => section.id === item.section_id);
	return section ? { name: section.name, localization: section.localization } : { name: menu.name, localization: menu.localization };
}

function createItem(i: MenuItem, m: Menu, items: MenuItem[], restaurant: Restaurant) {
	if(i.published_at) {
		i = getPriceAndAvailability(i) as MenuItem;
		const menuItem: MenuItem = {
			id: i.id,
			menu_group_id: m.menu_group_id,
			menu_id: m.id,
			section_id: i.section_id,
			parent: getSectionOrMenuName(i, m), // Property that contains the section/menu name of the item, used for reporting tools
			name: i.name,
			description: getDynamicFieldValue(i, i.localization, 'description', restaurant.locales),
			ingredients: getDynamicFieldValue(i, i.localization, 'ingredients', restaurant.locales),
			slug: i.slug,
			sku: i.sku,
			category: i.category,
			calories: Number.isInteger(i.calories) && i.calories! >= 0 ? i.calories : null,
			image: i.image,
			price: i.price,
			promos: i.promos,
			tax_rate: i.tax_rate,
			tax_rebate_eligibility: i.tax_rebate_eligibility,
			price_unit: i.price_unit,
			price_type: getDynamicFieldValue(i, i.localization, 'price_type', restaurant.locales),
			allergens: i.allergens,
			diets: i.diets,
			price_schedule: i.price_schedule,
			additional_prices: i.additional_prices,
			options: sortOption(i.options!, restaurant),
			sold_out: i.sold_out,
			unavailable: i.unavailable,
			alcoholic_beverage: i.alcoholic_beverage,
			order: i.order,
			localization: i.localization
		};
		items.push(menuItem);
	}
}
function transformResponse(data: any): HttpPayload {
	try {
		if (data) {
			let { menus } = data;
			const { restaurant } = data;
			const { menuGroups } = data;

			const dataObj: HttpPayload = {
				menuGroups: menuGroups,
				menus: [],
				restaurant: restaurant
			};

			// CLEAN UP THE TERNARY OPERATOR **THIS WAS A HOTFIX FOR PROD**
			const resto: Restaurant = {
				id: restaurant.id,
				app8_restaurant: restaurant.app8_restaurant,
				group: restaurant.group,
				name: restaurant.name,
				display_name: restaurant.display_name,
				image: restaurant.image,
				avs: restaurant.avs,
				slug: restaurant.slug,
				address: restaurant.address,
				gmap_place_id: restaurant.gmap_place_id,
				latitude: restaurant.latitude,
				longitude: restaurant.longitude,
				theme: restaurant.menu_configuration ? restaurant.menu_configuration.theme : null,
				primaryColor: restaurant.menu_configuration ? restaurant.menu_configuration.primary_color : null,
				defaultFont: restaurant.menu_configuration ? restaurant.menu_configuration.use_default_fonts : null,
				primaryFont: restaurant.menu_configuration ? restaurant.menu_configuration.primary_font : null,
				secondaryFont: restaurant.menu_configuration ? restaurant.menu_configuration.secondary_font : null,
				cardStyle: restaurant.menu_configuration ? restaurant.menu_configuration.card_style : null,
				browseMenuStyle: restaurant.menu_configuration ? restaurant.menu_configuration.browse_menu_style : null,
				membershipCustomization: getMembershipProgram(restaurant.menu_configuration, restaurant.locales),
				openMenuDropdownOnLoad: restaurant.menu_configuration?.open_menu_dropdown_on_load || false,
				favicon: restaurant.menu_configuration ? restaurant.menu_configuration.favicon : null,
				zone: restaurant.menu_configuration ? restaurant.menu_configuration.zone : null,
				isOrderAgain: !restaurant.disable_order_again,
				genericTableLocations: restaurant.generic_table_locations ? restaurant.generic_table_locations : null,
				tax_rate: restaurant.tax_rate,
				serviceCharge: restaurant.service_charge,
				serviceChargeLabel: restaurant.order_options && getDynamicFieldValue(restaurant.order_options, restaurant.order_options.localization, 'service_charge_label', restaurant.locales) ? getDynamicFieldValue(restaurant.order_options, restaurant.order_options.localization, 'service_charge_label', restaurant.locales)! : undefined,
				tipConfig: getTipConfig(restaurant.order_options, restaurant.locales),
				noTakeoutTimeslot: restaurant.order_options && restaurant.order_options.no_takeout_timeslot ? restaurant.order_options.no_takeout_timeslot : false,
				maxItemsPerCart: restaurant.order_options && restaurant.order_options.max_items_per_cart ? restaurant.order_options.max_items_per_cart : null,
				supportEmail: restaurant.order_options && restaurant.order_options.support_email ? restaurant.order_options.support_email : null,
				orders_paused: restaurant.orders_paused,
				pos_integrated: restaurant.pos_integrated,
				allow_open_tab: restaurant.allow_open_tab,
				loyalty_program: restaurant.loyalty_program,
				membership_program: restaurant.membership_program,
				payment_processor: restaurant.payment_processor,
				hide_items_special_instructions: restaurant.hide_items_special_instructions,
				hide_takeout_special_instructions: restaurant.hide_takeout_special_instructions,
				holiday_hours: restaurant.holiday_hours ? restaurant.holiday_hours : null,
				description: restaurant.description ? getDynamicFieldValue(restaurant, restaurant.localization, 'description', restaurant.locales) : '',
				delivery: restaurant.delivery,
				deliveryCharge: restaurant.delivery ? restaurant.delivery.delivery_charge : null,
				discount: restaurant.discount,
				voucher: getVoucher(restaurant.voucher, restaurant.locales),
				extraPaymentMethods: getExtraPaymentMethods(restaurant.extra_payment_methods, restaurant.locales),
				concourse: restaurant.concourse ? restaurant.concourse : null,
				localization: restaurant.localization,
				locales: restaurant.locales,
				marketplaceHub: restaurant.marketplace_hub,
				multipleMids: restaurant.multiple_mids,
				customQuestions: restaurant.order_options?.custom_questions || null,
				hideGuestInfo: restaurant.order_options?.hide_guest_info || null,
				catering: restaurant.catering,
				showSplitTaxes: restaurant.show_split_taxes,
			};
			dataObj.restaurant = resto;

			menus = orderBy(menus, ['order']);

			menus.forEach((m: Menu): void => {
				const sections: MenuSection[] = [];
				m.sections = orderBy(m.sections, ['order']);

				const menu_items: MenuItem[] = [];

				if (m.items){
					m.items = orderBy(m.items, ['order']);

					m.items.forEach(( i: MenuItem): void => {
						createItem(i, m, menu_items, resto);
					});
				}
				if (m.sections){
					m.sections.forEach((s: MenuSection): void => {
						let items: MenuItem[] = [];
						s.items = orderBy(s.items, ['order']);

						s.items.forEach((i: MenuItem): void => {
							createItem(i, m, items, resto);
						});
						items = orderBy(items, ['order']);
						const menuSection: MenuSection = {
							name: s.name,
							slug: s.slug,
							order: s.order,
							description: getDynamicFieldValue(s, s.localization, 'description', restaurant.locales),
							localization: s.localization,
							items
						};

						sections.push(menuSection);
					});
				}
				const menu: Menu = {
					name: m.name,
					id: m.id,
					slug: m.slug,
					image: m.image,
					description: getDynamicFieldValue(m, m.localization, 'description', restaurant.locales),
					sections: sections,
					dine_in: m.dine_in,
					on_demand: m.on_demand,
					scheduled: m.scheduled,
					member_only: m.member_only,
					availability: [],
					order: m.order,
					localization: m.localization,
					menu_group_id: m.menu_group_id,
					items: menu_items
				};
				dataObj.menus.push(menu);
			});
			return dataObj;
		} else {
			throw Error(i18n.t('utils.http.error_parsing'));
		}
	} catch (err) {
		throw err;
	}
}

const api = {
	getData: async (restaurantSlug: string) => {
		try {
			let data;
			if (process.env.NODE_ENV === 'development') {
				try {
					data = (await axios.get(`${process.env.VUE_APP_DATA_BUCKET}/${restaurantSlug}/data.json`)).data;
				} catch (e) {
					if (process.env.VUE_APP_USE_LOCAL_DB === 'true') {
						try {
							data = require(`../data.${restaurantSlug}-local-db.json`);
						}
						catch (e) {
							data = require('../data.db-test.json');
						}
					}
					else {
						data = require('../data.db-test.json');
					}
				}
			}
			else {
				const appBaseUrl = window.location.href.split(restaurantSlug)[0];
				data = (await axios.get(`${appBaseUrl}${restaurantSlug}/data.json`)).data;
			}
			return transformResponse(data);
		} catch (err) {
			throw err;
		}
	}
};

export default api;
